<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-2 text-black fw-700">{{ $t("appointment") }}</div>
              <div class="col-md-10 text-black fw-700">{{ $t("property") }}</div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="row border-bottom"
              v-for="(appointment, index) in appointments"
              v-bind:key="index"
            >
              <div class="col-md-2">
                <span class="text-muted fs-16px">
                  <span>{{
                    `${appointment.date} ${appointment.time}` | moment("MMM DD, hh:mm")
                  }}</span>
                </span>
              </div>
              <div class="col-md-10">
                <div class="text-black fs-16px">
                  {{ appointment.listing.propertyType }}
                  {{ appointment.listing.streetName }}
                  {{ appointment.listing.houseNr }}
                  {{ appointment.listing.houseAd ? appointment.listing.houseAd : "" }}
                </div>
                <small
                  class="text-muted"
                  v-for="(tenant, key) in appointment.user.tenants"
                  :key="key"
                >
                  {{ tenant.name }}
                  <!-- {{ appointment.listing.streetName }}
                  {{ appointment.listing.houseNr }}
                  {{ appointment.listing.houseAd ? appointment.listing.houseAd : "" }} -->
                </small>
                <small class="text-muted" v-if="appointment.user.tenants.length == 0">
                    {{ $t("No Tenant Found") }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
            <div class="col-md-12">
                <div class="text-black d-flex justify-content-between">
                    1 - 10 of 57
                    <div class="d-flex">
                        <div class="mdi-icon">
                            <i class="mdi mdi-chevron-left"></i>
                        </div>
                        <div class="mdi-icon">
                            <i class="mdi mdi-chevron-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    appointments() {
      let appointments = this.$store.state.calendar.appointments;
      return appointments;
    },
  },
  mounted() {
    this.getAppointments();
  },
  methods: {
    ...mapActions("calendar", ["getAppointments"]),
  },
};
</script>
<style lang="scss" scoped>
.card {
  .card-header {
    background-color: #fff;
  }
}

.mdi-icon {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1.5px solid #c9c9c9;
  box-shadow: 0px 4px 40px rgba(14, 8, 84, 0.1);
  display: flex;
  border-radius: 50%;
  margin-left: 1rem;

  i {
    margin: auto;
  }
}
</style>
