<template>
  <div class="container" data-app>
    <div class="row">
      <div class="col-md-12">
        <h1>
          {{ $t("Calendar Overview") }}
        </h1>
      </div>
    </div>
    <!-- filters -->
    <div class="row">
      <div class="col-md-12">
        <nav class="nav tabs-border-bottom mb-4">
          <a
            class="mr-4 pb-2"
            aria-current="page"
            href="#"
            :class="{ active: activeTab == 'calendar_view' }"
            @click="activeTab = 'calendar_view'"
          >
            <div>
              {{ $t("broker.calendar.calendar_view") }}
            </div>
          </a>
          <a
            class="mr-4 pb-2"
            href="#"
            :class="{ active: activeTab == 'list_view' }"
            @click="activeTab = 'list_view'"
          >
            <div>
              {{ $t("list_view") }}
            </div>
          </a>
        </nav>
      </div>
    </div>

    <CalendarView v-if="activeTab == 'calendar_view'"></CalendarView>
    <ListView v-if="activeTab == 'list_view'"></ListView>
  </div>
</template>
<script>
import CalendarView from "./Calendar.vue";

import ListView from "./ListView.vue";
export default {
  components: {
    CalendarView,
    ListView,
  },
  data() {
    return {
      activeTab: "calendar_view",
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
nav {
  a {
    color: #9f9f9f;
    font-size: 1rem;
    font-weight: 600;
  }

  a.active {
    color: #005c17 !important;
    border-bottom: 3px solid #005c17;
  }
}
</style>
