<template>
  <div class="row">
    <div class="col-md-12 mb-5 pb-5">
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                {{ $t("Today") }}
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>{{ $t("Day") }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>{{ $t("Week") }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>{{ $t("Month") }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>{{ $t("4 days") }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="updateRange"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card class="card-class" color="lighten-4" min-width="350px" flat>
                <v-card-title v-html="selectedEvent.name"></v-card-title>
                <v-card-subtitle v-html="selectedEvent.detailsNew"></v-card-subtitle>
                <v-card-text class="pb-0">
                  <v-alert class="card-text-bg mb-0">{{
                    selectedEvent.time | moment("MMMM D - HH.mm")
                  }}</v-alert>
                  <!-- :class="selectedEvent.color" -->
                </v-card-text>
                <v-card-actions class="justify-content-end">
                  <v-btn
                    class="text-primary fw-700"
                    text
                    @click="addToCalender(selectedEvent.appointmentID)"
                  >
                    {{ $t("broker.calendar.add_to_calendar") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
    <a
      class="download"
      id="downloadLink"
      ref="downloadLink"
      style="display: none"
      download="event.ics"
      :href="downloadLink"
    ></a>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import 'vuetify/dist/vuetify.min.css'

export default {
  name: "CalendarView",
  components: {},
  data() {
    return {
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      downloadLink: "",
    };
  },
  mounted() {
    this.$refs.calendar.checkChange();
    this.getAppointments();
  },
  computed: {
    ...mapGetters("calendar", ["appointments"]),
  },
  watch: {
    appointments() {
      let events = [];

      for (let appointment of this.appointments) {
        let name = `${appointment.listing.streetName} ${appointment.listing.houseNr} ${
          appointment.listing.houseAd ?? ""
        } ${appointment.listing.zipcode} ${appointment.listing.city}`;
        events.push({
          name: name,
          start: new Date(`${appointment.date} ${appointment.time}`),
          end: new Date(`${appointment.date} ${appointment.time}`),
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: true,
          details: `${appointment.user.first_name} - ${appointment.listing.streetName} <br> ${appointment.date} ${appointment.time}`,
          detailsNew: `${appointment.user.first_name} - ${appointment.listing.streetName}`,
          time: new Date(`${appointment.date} ${appointment.time}`),
          appointmentID: appointment.id,
        });
      }

      this.events = events;
    },
  },
  methods: {
    ...mapActions("calendar", ["getAppointments"]),
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      console.log(start, end);
      // const events = []

      // const min = new Date(`${start.date}T00:00:00`)
      // const max = new Date(`${end.date}T23:59:59`)
      // const days = (max.getTime() - min.getTime()) / 86400000
      // const eventCount = this.rnd(days, days + 20)

      // for (let i = 0; i < eventCount; i++) {
      //     const allDay = this.rnd(0, 3) === 0
      //     const firstTimestamp = this.rnd(min.getTime(), max.getTime())
      //     const first = new Date(firstTimestamp - (firstTimestamp % 900000))
      //     const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
      //     const second = new Date(first.getTime() + secondTimestamp)

      //     events.push({
      //         name: this.names[this.rnd(0, this.names.length - 1)],
      //         start: first,
      //         end: second,
      //         color: this.colors[this.rnd(0, this.colors.length - 1)],
      //         timed: !allDay,
      //     })
      // }

      // this.events = events
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    addToCalender(appointmentID) {
      try {
        window.axios
          .get(`/broker/appointment/${appointmentID}/export`)
          .then((response) => {
            this.selectedOpen = false;
            this.createAndDownloadICSFile(response.data);
          });
      } catch (error) {
        console.log(error.message);
      }
    },

    createAndDownloadICSFile(fileData) {
      this.downloadLink = "";
      var data = new File([fileData], { type: "text/plain" });
      this.downloadLink = window.URL.createObjectURL(data);
      setTimeout(() => {
        var elem = this.$refs.downloadLink;
        elem.click();
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-class {
  font-size: 14x;
  font-family: "Plus Jakarta sans";
}

.card-class .v-card__title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.card-text-bg {
  background-color: #f7f7fd;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  border: 1.5px solid $gray2;
  border-radius: 8px;
}

.card-class .v-btn--text {
  font-weight: 700;
}
</style>
